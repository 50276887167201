<template>
  <Footer :columns="columns" />
</template>

<script>
import Footer from 'chimera/all/themes/blueflow/components/layout/Footer'

export default {
  components: {
    Footer,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      columns: [
        {
          title: 'Sobre nosotros',
          links: [
            {
              text: 'Contacto',
              to: '/contacto',
            },
          ],
        },
        {
          title: 'Profesionales',
          links: [
            {
              text: 'Inscríbete como profesional',
              to: '/contacto-pro',
            },
          ],
        },
        {
          title: 'Consumidores',
          links: [
            {
              text: 'Información sobre agentes inmobiliarios',
              to: '/informacion-sobre-agentes-inmobiliarios',
            },
          ],
        },
      ],
    }
  },
}
</script>
